import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BrandsIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M16.916,7.067l-.022-.061,0-.005a1.565,1.565,0,0,0-1.508-1.045H11.689L10.527,2.425A1.581,1.581,0,0,0,9.011,1.317a.261.261,0,0,0-.028,0A1.578,1.578,0,0,0,7.466,2.435L6.335,5.955H2.607A1.608,1.608,0,0,0,1,7.558a1.615,1.615,0,0,0,.671,1.313l3.014,2.177-1.16,3.517a1.577,1.577,0,0,0,.58,1.8,1.583,1.583,0,0,0,1.894,0l3.014-2.182,2.982,2.177a1.606,1.606,0,0,0,2.247-.355,1.616,1.616,0,0,0,.234-1.427L13.345,11.05,16.33,8.87A1.582,1.582,0,0,0,16.916,7.067Zm-1.865,1L11.7,10.514l1.437,4.5a.21.21,0,0,1-.134.261.214.214,0,0,1-.186-.03l-3.8-2.783L5.173,15.237a.187.187,0,0,1-.154.037.2.2,0,0,1-.134-.084.208.208,0,0,1-.03-.185l1.481-4.491L2.493,7.732A.2.2,0,0,1,2.41,7.6a.207.207,0,0,1,.2-.24H7.36L8.8,2.857a.2.2,0,0,1,.2-.138H9a.237.237,0,0,1,.192.143l1.48,4.495h4.707a.208.208,0,0,1,.122.375l-.453.331Z" />
    <path d="M9,11.439a1.976,1.976,0,1,1,1.975-1.976A1.978,1.978,0,0,1,9,11.439ZM9,8.476a.988.988,0,1,0,.987.987A.988.988,0,0,0,9,8.476Z" />
  </SvgIcon>
);

export const CategoriesIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M4.5,7.8A3.3,3.3,0,1,1,7.8,4.5,3.3,3.3,0,0,1,4.5,7.8Zm0-5A1.7,1.7,0,1,0,6.2,4.5,1.7,1.7,0,0,0,4.5,2.8Z" />
    <path d="M13.5,7.8a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,13.5,7.8Zm0-5a1.7,1.7,0,1,0,1.7,1.7A1.7,1.7,0,0,0,13.5,2.8Z" />
    <path d="M4.5,16.8a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,4.5,16.8Zm0-5a1.7,1.7,0,1,0,1.7,1.7A1.7,1.7,0,0,0,4.5,11.8Z" />
    <path d="M13.5,16.8a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,13.5,16.8Zm0-5a1.7,1.7,0,1,0,1.7,1.7A1.7,1.7,0,0,0,13.5,11.8Z" />
  </SvgIcon>
);

export const BookIcon = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1777_3896)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7769 8C11.7769 7.6134 11.4635 7.3 11.0769 7.3C10.6903 7.3 10.3769 7.6134 10.3769 8V8.30078H9.84596C8.42956 8.30078 7.2998 9.46392 7.2998 10.8758V22.1258C7.2998 23.5376 8.42956 24.7008 9.84596 24.7008H16.6402C16.4188 24.2623 16.2497 23.7929 16.141 23.3008H9.84596C9.22315 23.3008 8.6998 22.785 8.6998 22.1258V12.7H23.2998V16.142C23.7919 16.2507 24.2613 16.4198 24.6998 16.6412V12.0167C24.6999 12.0111 24.7 12.0056 24.7 12C24.7 11.9944 24.6999 11.9889 24.6998 11.9833V10.8758C24.6998 9.46392 23.57 8.30078 22.1537 8.30078H21.6231V8C21.6231 7.6134 21.3097 7.3 20.9231 7.3C20.5365 7.3 20.2231 7.6134 20.2231 8V8.30078H11.7769V8ZM10.6541 9.70078C10.7716 9.78995 10.9181 9.84286 11.0769 9.84286C11.2358 9.84286 11.3823 9.78995 11.4997 9.70078H20.5003C20.6177 9.78995 20.7642 9.84286 20.9231 9.84286C21.0819 9.84286 21.2284 9.78995 21.3459 9.70078H22.1537C22.7765 9.70078 23.2998 10.2166 23.2998 10.8758V11.3H8.6998V10.8758C8.6998 10.2166 9.22315 9.70078 9.84596 9.70078H10.6541Z"
        fill="#232429"
      />
      <path
        d="M22.349 20.1395C22.349 19.9469 22.1928 19.7907 22.0001 19.7907C21.8075 19.7907 21.6513 19.9469 21.6513 20.1395V21.8755C21.6513 22.0699 21.7484 22.2515 21.9102 22.3593L23.202 23.2205C23.3623 23.3273 23.5789 23.284 23.6857 23.1237C23.7926 22.9634 23.7493 22.7468 23.589 22.64L22.349 21.8133V20.1395Z"
        fill="#232429"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0001 17C19.2387 17 17.0001 19.2386 17.0001 22C17.0001 24.7614 19.2387 27 22.0001 27C24.7615 27 27.0001 24.7614 27.0001 22C27.0001 19.2386 24.7615 17 22.0001 17ZM17.6978 22C17.6978 19.6239 19.624 17.6977 22.0001 17.6977C24.3762 17.6977 26.3024 19.6239 26.3024 22C26.3024 24.3761 24.3762 26.3023 22.0001 26.3023C19.624 26.3023 17.6978 24.3761 17.6978 22Z"
        fill="#232429"
      />
      <path
        d="M22.349 20.1395C22.349 19.9469 22.1928 19.7907 22.0001 19.7907C21.8075 19.7907 21.6513 19.9469 21.6513 20.1395V21.8755C21.6513 22.0699 21.7484 22.2515 21.9102 22.3593L23.202 23.2205C23.3623 23.3273 23.5789 23.284 23.6857 23.1237C23.7926 22.9634 23.7493 22.7468 23.589 22.64L22.349 21.8133V20.1395Z"
        stroke="#232429"
        strokeWidth="0.6"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0001 17C19.2387 17 17.0001 19.2386 17.0001 22C17.0001 24.7614 19.2387 27 22.0001 27C24.7615 27 27.0001 24.7614 27.0001 22C27.0001 19.2386 24.7615 17 22.0001 17ZM17.6978 22C17.6978 19.6239 19.624 17.6977 22.0001 17.6977C24.3762 17.6977 26.3024 19.6239 26.3024 22C26.3024 24.3761 24.3762 26.3023 22.0001 26.3023C19.624 26.3023 17.6978 24.3761 17.6978 22Z"
        stroke="#232429"
        strokeWidth="0.6"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1777_3896">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const BasketHeaderIcon = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.682 17.7388C12.682 17.3434 13.0025 17.0229 13.3979 17.0229H17.216C17.6114 17.0229 17.932 17.3434 17.932 17.7388C17.932 18.1342 17.6114 18.4547 17.216 18.4547H13.3979C13.0025 18.4547 12.682 18.1342 12.682 17.7388Z"
      fill="#232429"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.01404 7.57565C6.09158 7.18794 6.46874 6.9365 6.85645 7.01404L7.85811 7.21437C8.98948 7.44065 9.84047 8.37915 9.95528 9.5272L10.0367 10.341H22.9951C24.8891 10.341 26.2789 12.121 25.8195 13.9585L24.7478 18.2454C24.2963 20.0514 22.6736 21.3183 20.8121 21.3183H12.2279C10.8991 21.3183 9.77994 20.3254 9.62162 19.0061L8.67712 11.1352L8.53057 9.66967C8.47838 9.14783 8.09157 8.72124 7.57731 8.61839L6.57565 8.41805C6.18794 8.34051 5.9365 7.96335 6.01404 7.57565ZM10.1957 11.7729L11.0432 18.8355C11.1152 19.4352 11.6239 19.8865 12.2279 19.8865H20.8121C22.0166 19.8865 23.0666 19.0667 23.3587 17.8982L24.4304 13.6113C24.6639 12.6774 23.9576 11.7729 22.9951 11.7729H10.1957Z"
      fill="#232429"
    />
    <path
      d="M14.3524 23.9432C14.3524 24.734 13.7114 25.375 12.9206 25.375C12.1298 25.375 11.4888 24.734 11.4888 23.9432C11.4888 23.1524 12.1298 22.5114 12.9206 22.5114C13.7114 22.5114 14.3524 23.1524 14.3524 23.9432Z"
      fill="#232429"
    />
    <path
      d="M22.9433 23.9432C22.9433 24.734 22.3023 25.375 21.5115 25.375C20.7207 25.375 20.0797 24.734 20.0797 23.9432C20.0797 23.1524 20.7207 22.5114 21.5115 22.5114C22.3023 22.5114 22.9433 23.1524 22.9433 23.9432Z"
      fill="#232429"
    />
  </svg>
);

export const BasketIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path d="M10.834,20.175H26.287a.981.981,0,0,0,.936-.7L30.629,7.7a.97.97,0,0,0-.161-.855.992.992,0,0,0-.784-.386H8.973L8.387,3.853a.976.976,0,0,0-.955-.762H2.313a.973.973,0,1,0,0,1.946H6.637L9.671,18.494A2.65,2.65,0,0,0,10.8,23.541a.223.223,0,0,0,.037,0h.569a2.816,2.816,0,1,0,2.339,0h9.361a2.817,2.817,0,1,0,2.339,0h.838a.973.973,0,1,0,0-1.946H10.834a.709.709,0,1,1,0-1.418Zm13.439,5.154h0a.762.762,0,0,1,.539,1.3.752.752,0,0,1-.539.225h0a.765.765,0,0,1-.761-.765A.763.763,0,0,1,24.273,25.329Zm-11.7,0h0a.764.764,0,1,1-.762.762A.764.764,0,0,1,12.574,25.329ZM9.41,8.4H28.38l-2.843,9.831H11.625Z" />
  </SvgIcon>
);

export const SearchIcon = (props) => (
  <svg {...props} viewBox="0 0 18 18">
    <path d="M15.782,14.956l-3.45-3.589A5.891,5.891,0,1,0,11.206,12.4l3.479,3.615a.765.765,0,1,0,1.1-1.063ZM7.852,3.279A4.327,4.327,0,1,1,3.526,7.605h0A4.331,4.331,0,0,1,7.852,3.279Z" />
  </svg>
);

export const ProfileIcon = (props) => (
  <svg {...props} viewBox="0 0 24 24">
    <g transform="translate(-61.178 -45.945)">
      <path
        d="M176.146,52.59c-.187,2.528-2.1,4.59-4.207,4.59s-4.023-2.062-4.207-4.59a4.223,4.223,0,1,1,8.415,0Z"
        transform="translate(-98.761)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M73.177,304c-4.16,0-8.381,2.295-9.162,6.627a.8.8,0,0,0,.748,1.023H81.592a.8.8,0,0,0,.748-1.023C81.559,306.295,77.337,304,73.177,304Z"
        transform="translate(0 -243.76)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </g>
    <rect width="24" height="24" fill="none" />
  </svg>
);

export const YoutubeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 36">
    <g transform="translate(7.896 10.774)">
      <path
        d="M26.469,27.817H13.228A3.231,3.231,0,0,1,10,24.59v-8.1a3.231,3.231,0,0,1,3.228-3.227H26.469A3.231,3.231,0,0,1,29.7,16.491v8.1A3.231,3.231,0,0,1,26.469,27.817ZM13.228,14.577a1.916,1.916,0,0,0-1.915,1.914v8.1A1.916,1.916,0,0,0,13.228,26.5H26.469a1.916,1.916,0,0,0,1.915-1.914v-8.1a1.916,1.916,0,0,0-1.915-1.914Z"
        transform="translate(-10 -13.264)"
      />
      <g transform="translate(7.222 3.858)">
        <path
          d="M19.823,24.588a.664.664,0,0,1-.338-.093.657.657,0,0,1-.318-.563V18.816a.657.657,0,0,1,.964-.581l4.815,2.544a.657.657,0,0,1,0,1.16l-4.815,2.572A.653.653,0,0,1,19.823,24.588Zm.656-4.683v2.932l2.759-1.473Z"
          transform="translate(-19.167 -18.161)"
        />
      </g>
    </g>
  </SvgIcon>
);

export const InstagramIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 36 36">
    <g transform="translate(7.912 8.236)">
      <g transform="translate(4.887 4.887)">
        <path
          d="M21.169,26.093a4.924,4.924,0,1,1,4.924-4.924A4.929,4.929,0,0,1,21.169,26.093Zm0-8.535a3.611,3.611,0,1,0,3.612,3.611A3.615,3.615,0,0,0,21.169,17.558Z"
          transform="translate(-16.245 -16.245)"
        />
      </g>
      <path
        d="M26.51,29.739H13.27a3.232,3.232,0,0,1-3.228-3.228V13.27a3.232,3.232,0,0,1,3.228-3.228H26.51a3.232,3.232,0,0,1,3.229,3.228V26.511A3.232,3.232,0,0,1,26.51,29.739ZM13.27,11.355a1.917,1.917,0,0,0-1.915,1.915V26.511a1.917,1.917,0,0,0,1.915,1.915H26.51a1.917,1.917,0,0,0,1.915-1.915V13.27a1.917,1.917,0,0,0-1.915-1.915Z"
        transform="translate(-10.042 -10.042)"
      />
      <circle cx="1.277" cy="1.277" r="1.277" transform="translate(13.97 3.137)" />
    </g>
  </SvgIcon>
);

export const FacebookIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 36">
    <g transform="translate(12.798 8.198)">
      <path
        d="M22.836,29.7H18.253V21.062H15.989V16.687H18.3l.106-2.433A4.43,4.43,0,0,1,22.847,10h.061l3.354.045-.1,4.3H23.3a.464.464,0,0,0-.464.464v1.881h3.426l-.336,4.375H22.836ZM19.459,28.49H21.63V19.856h3.178l.15-1.963H21.63V14.806a1.671,1.671,0,0,1,1.669-1.67h1.686l.043-1.9-2.137-.028h-.044a3.228,3.228,0,0,0-3.24,3.1l-.158,3.586H17.2v1.963h2.264Z"
        transform="translate(-15.989 -10)"
      />
    </g>
  </SvgIcon>
);

export const LeftArrowIcon = (props) => (
  <svg viewBox="0 0 26 26" {...props}>
    <g transform="translate(6 2)">
      <path
        d="M12.547,22.147a1.476,1.476,0,0,1-.959-.354L.522,12.306a1.475,1.475,0,0,1,0-2.213L11.573.384A1.475,1.475,0,0,1,13.521,2.6l-9.79,8.572,9.775,8.381a1.475,1.475,0,0,1-.959,2.6Z"
        transform="translate(-0.023 -0.058)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const CloseIcon = (props) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g id="Mask_Group_3" data-name="Mask Group 3" transform="translate(-67 -1662)">
      <g id="Group_1443" data-name="Group 1443" transform="translate(550.932 1924.891) rotate(135)">
        <path
          id="Union_2"
          data-name="Union 2"
          d="M6.521,14.023V8.479H.978a.978.978,0,1,1,0-1.957H6.521V.978a.978.978,0,0,1,1.957,0V6.522h5.544a.978.978,0,1,1,0,1.957H8.478v5.544a.978.978,0,0,1-1.957,0Z"
          transform="translate(148.651 506.848)"
          fill="#939393"
        />
      </g>
    </g>
  </SvgIcon>
);

export const CheckMarkIcon = (props) => (
  <svg {...props} width="41" height="41" viewBox="0 0 41 41">
    <path
      d="M40.4355 20.9923C40.4355 9.95064 31.4772 0.99231 20.4355 0.99231C9.39388 0.99231 0.435547 9.95064 0.435547 20.9923C0.435547 32.034 9.39388 40.9923 20.4355 40.9923C31.4772 40.9923 40.4355 32.034 40.4355 20.9923Z"
      fill="url(#paint0_linear_648_49397)"
    />
    <path
      d="M28.4827 14.2865L17.2169 27.6982L12.3887 22.3335"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="url(#paint0_linear_648_49397)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_648_49397"
        x1="23.3787"
        y1="13.192"
        x2="20.4356"
        y2="40.9923"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#09C505" />
        <stop offset="1" stopColor="#07A803" />
      </linearGradient>
    </defs>
  </svg>
);

export const LogoutIcon = (props) => (
  <SvgIcon {...props} width="16" height="13.5" viewBox="0 0 16 13.5">
    <path
      id="log-out-outline_1_"
      data-name="log-out-outline (1)"
      d="M73.375,105.375v1.562a1.563,1.563,0,0,1-1.563,1.563h-6.25A1.563,1.563,0,0,1,64,106.937V97.563A1.563,1.563,0,0,1,65.563,96H71.5a1.86,1.86,0,0,1,1.875,1.563v1.562m2.5,6.25L79,102.25l-3.125-3.125M70.25,102.25h8.125"
      transform="translate(-63.5 -95.5)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </SvgIcon>
);

export const AttentionIcon = (props) => (
  <SvgIcon {...props} width="35" height="35" viewBox="0 0 35 35">
    <circle id="Ellipse_3" data-name="Ellipse 3" cx="17.5" cy="17.5" r="17.5" fill="currentColor" />
    <g id="done_icn-2" data-name="done_icn" transform="translate(1 1)">
      <rect
        id="Rectangle_1935"
        data-name="Rectangle 1935"
        width="33"
        height="33"
        fill="currentColor"
        opacity="0"
      />
      <g id="alert-outline" transform="translate(-224.427 -74.004)">
        <path
          id="Path_15055"
          data-name="Path 15055"
          d="M240.927,80a.988.988,0,0,0-.955,1.032l.477,13.931a.5.5,0,0,0,.477.516h0a.5.5,0,0,0,.477-.516l.477-13.931A.988.988,0,0,0,240.927,80Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <ellipse
          id="Ellipse_445"
          data-name="Ellipse 445"
          cx="0.955"
          cy="0.79"
          rx="0.955"
          ry="0.79"
          transform="translate(239.971 99.428)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </SvgIcon>
);

export const AttentionSimpleIcon = (props) => (
  <SvgIcon {...props} width="17.496" height="17.496" viewBox="0 0 17.496 17.496">
    <g id="alert-circle-outline" transform="translate(-63.25 -63.25)">
      <path
        id="Path_15855"
        data-name="Path 15855"
        d="M80,72a8,8,0,1,0-8,8A8,8,0,0,0,80,72Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        id="Path_15856"
        data-name="Path 15856"
        d="M250.254,160.3l.239,5.068.238-5.068a.238.238,0,0,0-.241-.249h0a.239.239,0,0,0-.236.249Z"
        transform="translate(-178.495 -92.044)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_15857"
        data-name="Path 15857"
        d="M236.831,329.572a.831.831,0,1,1,.831-.831A.831.831,0,0,1,236.831,329.572Z"
        transform="translate(-164.833 -252.913)"
        fill="#fff"
      />
    </g>
  </SvgIcon>
);

export const BoxIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 35">
    <path
      d="M37.7,9.475l0-.011a5.124,5.124,0,0,0-.62-1.29l-2.8-4.206a3.07,3.07,0,0,0-2.56-1.37H9.162A3.07,3.07,0,0,0,6.6,3.968L3.8,8.173a5.123,5.123,0,0,0-.62,1.29l0,.011a5.119,5.119,0,0,0-.238,1.542v23.5A3.08,3.08,0,0,0,6.014,37.6H34.861a3.08,3.08,0,0,0,3.076-3.076v-23.5A5.121,5.121,0,0,0,37.7,9.475ZM21.463,4.648h10.25a1.024,1.024,0,0,1,.853.457L35,8.75H21.463ZM8.309,5.105a1.023,1.023,0,0,1,.853-.457h10.25v4.1H5.879ZM35.887,34.521a1.027,1.027,0,0,1-1.025,1.025H6.014a1.027,1.027,0,0,1-1.025-1.025c0-24.986,0-23.554.008-23.721H35.879C35.891,10.966,35.887,9.51,35.887,34.521Z"
      transform="translate(-2.938 -2.598)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const ClockIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <defs>
      <clipPath id="clip-Processing_icn">
        <rect width="56" height="56" />
      </clipPath>
    </defs>
    <g id="Processing_icn" clipPath="url(#clip-Processing_icn)">
      <g id="Processing_icn-2" data-name="Processing_icn" transform="translate(-3060 -10759)">
        <path
          id="Path_15189"
          data-name="Path 15189"
          d="M80,64A16,16,0,1,0,96,80,16,16,0,0,0,80,64Z"
          transform="translate(3008 10707)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Path_15190"
          data-name="Path 15190"
          d="M256,128v13.125h8.75"
          transform="translate(2831.5 10648.034)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
      </g>
    </g>
  </SvgIcon>
);

export const DeliveryTruckIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <defs>
      <clipPath id="clip-Shipping_icn">
        <rect width="56" height="56" />
      </clipPath>
    </defs>
    <g id="Shipping_icn" clipPath="url(#clip-Shipping_icn)">
      <g id="Shipping_icn-2" data-name="Shipping_icn" transform="translate(-2937.888 -10845)">
        <path
          id="Path_15193"
          data-name="Path 15193"
          d="M13.446,22.2h0l18.468.044a1.534,1.534,0,0,1,1.1.442,1.4,1.4,0,0,1,.442,1.1l-.044,4.816,4.816.088a1.661,1.661,0,0,1,1.325.751L43,35.366a.95.95,0,0,0,.53.4l.928.177a1.483,1.483,0,0,1,1.193,1.5v4.506a1.509,1.509,0,0,1-1.5,1.5h-2.3a3.769,3.769,0,0,1-7.511,0l-11.222-.088a3.811,3.811,0,0,1-7.6-.044H13.535a1.49,1.49,0,0,1-1.5-1.5L11.9,23.791A1.575,1.575,0,0,1,13.446,22.2Zm19.086.972a.84.84,0,0,0-.574-.221h0l-18.468-.044a.792.792,0,0,0-.8.8l.088,14.359,19.882-.088.044-8.659h0l.044-5.523A.958.958,0,0,0,32.533,23.172Zm11.62,19.484a.931.931,0,0,0,.574-.221.84.84,0,0,0,.221-.574V37.354a.809.809,0,0,0-.619-.8l-.928-.177a1.55,1.55,0,0,1-1.016-.707l-3.446-5.92a.75.75,0,0,0-.707-.4l-4.816-.088-.044,13.343h.972A3.824,3.824,0,0,1,38.1,39.166a3.757,3.757,0,0,1,3.755,3.446h2.3Zm-6.053,3.4a3.093,3.093,0,1,0-3.093-3.093A3.1,3.1,0,0,0,38.1,46.058Zm-18.733,0a3.093,3.093,0,1,0-3.093-3.093A3.1,3.1,0,0,0,19.367,46.058Zm-5.788-3.579h1.988a3.782,3.782,0,0,1,7.511.044l9.543.044V38.591l-19.882.088v2.96A.9.9,0,0,0,13.579,42.479Z"
          transform="translate(2937.989 10838.451)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_15194"
          data-name="Path 15194"
          d="M66.516,41.3l2.563,2.739a.347.347,0,0,1,0,.486.628.628,0,0,1-.265.088.382.382,0,0,1-.265-.133l-2.563-2.739a.347.347,0,0,1,0-.486A.463.463,0,0,1,66.516,41.3Zm6.45,3.4a.382.382,0,0,1-.265-.133l-6.583-7.2a.345.345,0,0,1,.53-.442l6.583,7.157a.315.315,0,0,1-.044.486A.243.243,0,0,1,72.966,44.7Z"
          transform="translate(2886.466 10830.31)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </g>
  </SvgIcon>
);

export const CompleteIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <defs>
      <clipPath id="clip-Complete_icn">
        <rect width="56" height="56" />
      </clipPath>
    </defs>
    <g id="Complete_icn" clipPath="url(#clip-Complete_icn)">
      <g id="Group_6841" data-name="Group 6841" transform="translate(-3061 -10907)">
        <g
          id="Ellipse_3"
          data-name="Ellipse 3"
          transform="translate(3072 10918)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.5"
        >
          <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
          <circle cx="17.5" cy="17.5" r="16.25" fill="none" />
        </g>
        <path
          id="Path_15054"
          data-name="Path 15054"
          d="M9.487,18.533a1.255,1.255,0,0,1-.933-.416L3.821,12.846a1.254,1.254,0,1,1,1.866-1.675l3.724,4.148L17.133,5.254a1.254,1.254,0,0,1,1.99,1.526L10.481,18.043a1.257,1.257,0,0,1-.946.49Z"
          transform="translate(3078.059 10923.852)"
          fill="currentColor"
        />
      </g>
    </g>
  </SvgIcon>
);

export const CancelledIcon = (props) => (
  <SvgIcon viewBox="0 0 56 56" {...props}>
    <g id="canceled" transform="translate(-2934 -10913)">
      <circle
        id="Ellipse_470"
        data-name="Ellipse 470"
        cx="28"
        cy="28"
        r="28"
        transform="translate(2934 10913)"
        fill="none"
      />
      <g id="complete_icon" data-name="complete icon" transform="translate(-127 6)">
        <g
          id="Ellipse_3"
          data-name="Ellipse 3"
          transform="translate(3072 10918)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.5"
        >
          <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
          <circle cx="17.5" cy="17.5" r="16.25" fill="none" />
        </g>
        <g
          id="Group_7959"
          data-name="Group 7959"
          transform="translate(3566.785 11188.784) rotate(135)"
        >
          <path
            id="Union_2"
            data-name="Union 2"
            d="M8.472,18.214v-7.2H1.27a1.271,1.271,0,0,1,0-2.542h7.2v-7.2a1.271,1.271,0,1,1,2.542,0v7.2h7.2a1.271,1.271,0,0,1,0,2.542h-7.2v7.2a1.271,1.271,0,0,1-2.542,0Z"
            transform="translate(148.651 506.848)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const ArrowDownIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      id="Path_14790"
      data-name="Path 14790"
      d="M15.426,12.108a.5.5,0,0,1-.465.317H2.73a.5.5,0,0,1-.353-.853l5.9-5.9a.5.5,0,0,1,.7-.012l6.334,5.9A.5.5,0,0,1,15.426,12.108Z"
      transform="translate(0 0.05)"
      fill="currentColor"
    />
    <rect id="Rectangle_495" data-name="Rectangle 495" width="18" height="18" fill="none" />
  </SvgIcon>
);

export const ArrowUpIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      id="Path_14790"
      data-name="Path 14790"
      d="M15.426,5.842a.5.5,0,0,0-.465-.317H2.73a.5.5,0,0,0-.353.853l5.9,5.9a.5.5,0,0,0,.7.012l6.334-5.9A.5.5,0,0,0,15.426,5.842Z"
      fill="currentColor"
    />
    <rect id="Rectangle_495" data-name="Rectangle 495" width="18" height="18" fill="none" />
  </SvgIcon>
);

export const PaidIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <g id="paid" transform="translate(-2934 -10761)">
      <path
        id="Path_15860"
        data-name="Path 15860"
        d="M25.53,24.518H17.364a.817.817,0,1,0,0,1.633H25.53a.817.817,0,1,0,0-1.633Zm0,3.267H17.364a.817.817,0,0,0,0,1.633H25.53a.817.817,0,0,0,0-1.633ZM20.63,18.8h.2v1.633H19.814a.817.817,0,0,0,0,1.633h1.021a.612.612,0,0,0,1.225,0h.2a2.45,2.45,0,1,0,0-4.9h-.2V15.535H23.08a.817.817,0,0,0,0-1.633H22.059a.612.612,0,0,0-1.225,0h-.2a2.45,2.45,0,1,0,0,4.9Zm1.633,0a.817.817,0,1,1,0,1.633h-.2V18.8ZM20.63,15.535h.2v1.633h-.2a.817.817,0,0,1,0-1.633Z"
        transform="translate(2940.552 10766.316)"
        fill="currentColor"
      />
      <path
        id="Path_15861"
        data-name="Path 15861"
        d="M11.047,38V11.4a1.666,1.666,0,0,1,.307-.99A.94.94,0,0,1,12.1,10H35.2a.939.939,0,0,1,.742.41,1.665,1.665,0,0,1,.308.99V38l-4.2-2.8-4.2,2.8-4.2-2.8-4.2,2.8-4.2-2.8Z"
        transform="translate(2938.353 10765)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </SvgIcon>
);

export const LocationIcon = (props) => (
  <svg {...props} viewBox="0 0 14.626 17.072">
    <path
      d="M9.314,1A7.291,7.291,0,0,0,4.026,13.331a.611.611,0,0,0,.114.145l4.221,4.2a1.359,1.359,0,0,0,1.91,0l4.206-4.192.014-.012,0,0a.611.611,0,0,0,.062-.071l.014-.019A7.3,7.3,0,0,0,9.314,1Zm0,1.219a6.084,6.084,0,0,1,4.313,10.387q-.021.022-.04.045L9.414,16.815a.122.122,0,0,1-.188,0L5.112,12.721l0,0a.611.611,0,0,0-.1-.1A6.085,6.085,0,0,1,9.314,2.219Zm.019,2.453a3.663,3.663,0,1,0,3.673,3.663A3.669,3.669,0,0,0,9.333,4.673Zm0,1.215A2.449,2.449,0,1,1,6.894,8.336,2.441,2.441,0,0,1,9.333,5.887Z"
      transform="translate(-2.004 -1)"
      fill="currentColor"
    />
  </svg>
);

export const CheckIcon = (props) => (
  <SvgIcon width="11" height="9.538" viewBox="0 0 11 9.538" {...props}>
    <path
      id="Path_14789"
      data-name="Path 14789"
      d="M7.646,14.3A.869.869,0,0,1,7,14.012L3.722,10.361A.868.868,0,1,1,5.015,9.2l2.579,2.873L12.942,5.1A.869.869,0,0,1,14.321,6.16l-5.985,7.8a.871.871,0,0,1-.655.339Z"
      transform="translate(-3.5 -4.763)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const DropdownIcon = (props) => (
  <SvgIcon width="10.29px" height="5.367px" viewBox="0 0 10.29 5.367" {...props}>
    <path
      id="Path_14791"
      data-name="Path 14791"
      d="M12,4.544a.389.389,0,0,0-.362-.247H2.124a.389.389,0,0,0-.275.664L6.435,9.55a.387.387,0,0,0,.275.114.391.391,0,0,0,.265-.1L11.9,4.97A.389.389,0,0,0,12,4.544Z"
      transform="translate(-1.734 -4.297)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const ReturnIcon = (props) => (
  <SvgIcon width="15px" height="15px" viewBox="0 0 15 15" {...props}>
    <circle
      id="Ellipse_507"
      data-name="Ellipse 507"
      cx="7.5"
      cy="7.5"
      r="7.5"
      fill="#e8e8e8"
      opacity="0.5"
    />
    <path
      id="back"
      d="M15.594,15.119a2.6,2.6,0,0,1-2.6,2.6H8.325a.316.316,0,0,1,0-.632H13a1.964,1.964,0,1,0,0-3.928H9.208l1.035.851a.316.316,0,1,1-.4.488L8.124,13.083l-.01-.01-.023-.024,0,0-.007-.007h0l-.009-.013A.279.279,0,0,1,8.055,13l0-.006-.005-.008-.005-.013q-.006-.014-.011-.028v0a.274.274,0,0,1-.014-.056c0-.006,0-.011,0-.017s0-.011,0-.017,0-.009,0-.014,0-.009,0-.014v-.017a.12.12,0,0,1,0-.017.273.273,0,0,1,.014-.056v0c0-.01.007-.019.011-.028s0-.009.005-.013l.005-.008,0-.006a.278.278,0,0,1,.017-.026l.009-.013h0l.01-.01a.28.28,0,0,1,.023-.023l.01-.01h0l1.716-1.41a.316.316,0,1,1,.4.488l-1.035.851H13A2.6,2.6,0,0,1,15.594,15.119Z"
      transform="translate(-4.302 -6.914)"
      fill="#232429"
    />
  </SvgIcon>
);

export const ReturnedIcon = (props) => (
  <SvgIcon viewBox="0 0 56 56" {...props}>
    <circle id="Ellipse_470" data-name="Ellipse 470" cx="28" cy="28" r="28" fill="none" />
    <path
      id="Path_16254"
      data-name="Path 16254"
      d="M38.615,25.753a9.008,9.008,0,0,1-9,9h-16.2a1.1,1.1,0,0,1,0-2.193h16.2a6.808,6.808,0,1,0,0-13.616H16.481l3.6,2.951a1.1,1.1,0,0,1-1.392,1.694l-5.948-4.9-.035-.035a1.03,1.03,0,0,1-.081-.083l-.037-.026s-.02-.029-.029-.043a.971.971,0,0,1-.061-.089V18.4l-.017-.029-.017-.043c-.015-.033-.029-.065-.04-.1a.943.943,0,0,1-.049-.193v-.274a.386.386,0,0,1,0-.061.933.933,0,0,1,.049-.193.262.262,0,0,1,.04-.1c.014-.032,0-.029.017-.043l-.012-.058v-.02a.943.943,0,0,1,.058-.089.514.514,0,0,1,.029-.043l.037-.038a1.031,1.031,0,0,1,.078-.078L12.706,17l5.948-4.9A1.1,1.1,0,1,1,20.046,13.8l-3.565,2.957H29.622a9.005,9.005,0,0,1,8.994,9Z"
      transform="translate(3.941 3.693)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const InfoIcon = (props, fill = '#2F80ED') => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M8 1.5C4.41037 1.5 1.5 4.41037 1.5 8C1.5 11.5896 4.41037 14.5 8 14.5C11.5896 14.5 14.5 11.5896 14.5 8C14.5 4.41037 11.5896 1.5 8 1.5ZM7.1875 7.1875C7.1875 6.97201 7.2731 6.76535 7.42548 6.61298C7.57785 6.4606 7.78451 6.375 8 6.375C8.21549 6.375 8.42215 6.4606 8.57452 6.61298C8.7269 6.76535 8.8125 6.97201 8.8125 7.1875V11.25C8.8125 11.4655 8.7269 11.6722 8.57452 11.8245C8.42215 11.9769 8.21549 12.0625 8 12.0625C7.78451 12.0625 7.57785 11.9769 7.42548 11.8245C7.2731 11.6722 7.1875 11.4655 7.1875 11.25V7.1875ZM8 5.5755C7.89327 5.57547 7.7876 5.55443 7.68901 5.51356C7.59042 5.47269 7.50084 5.41281 7.42539 5.33732C7.34994 5.26184 7.2901 5.17223 7.24929 5.07362C7.20847 4.97501 7.18747 4.86932 7.1875 4.76259C7.18753 4.65587 7.20857 4.55019 7.24944 4.4516C7.29031 4.35301 7.35019 4.26343 7.42568 4.18799C7.50116 4.11254 7.59077 4.0527 7.68938 4.01188C7.78799 3.97106 7.89368 3.95007 8.00041 3.95009C8.21595 3.95015 8.42264 4.03582 8.57501 4.18827C8.72739 4.34072 8.81296 4.54746 8.81291 4.763C8.81285 4.97854 8.72718 5.18524 8.57473 5.33761C8.42228 5.48998 8.21554 5.57555 8 5.5755Z"
      fill={fill}
    />
  </SvgIcon>
);

export const PlusIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M12 5.25V18.75M18.75 12H5.25"
      stroke="#153568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const MinusIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M18.75 12H5.25"
      stroke="#153568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const BlueDotIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill="#153568"
    />
  </SvgIcon>
);

export const GrayDotIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill="#E8E8E8"
    />
  </SvgIcon>
);

export const WebIcon = (props) => (
  <SvgIcon width="24" height="24" {...props}>
    <g opacity="0.5" fill="none">
      <path
        d="M12 3.91797C7.53662 3.91797 3.91797 7.53662 3.91797 12C3.91797 16.4634 7.53662 20.082 12 20.082C16.4634 20.082 20.082 16.4634 20.082 12C20.082 7.53662 16.4634 3.91797 12 3.91797Z"
        stroke="#585858"
        strokeWidth="1.02862"
        strokeMiterlimit="10"
      />
      <path
        d="M12.0009 3.91797C9.74458 3.91797 7.62305 7.53662 7.62305 12C7.62305 16.4634 9.74458 20.082 12.0009 20.082C14.2573 20.082 16.3788 16.4634 16.3788 12C16.3788 7.53662 14.2573 3.91797 12.0009 3.91797Z"
        stroke="#585858"
        strokeWidth="1.02862"
        strokeMiterlimit="10"
      />
      <path
        d="M6.61133 6.61133C8.09718 7.66627 9.9677 8.29534 11.9995 8.29534C14.0313 8.29534 15.9018 7.66627 17.3876 6.61133M17.3876 17.3876C15.9018 16.3327 14.0313 15.7036 11.9995 15.7036C9.9677 15.7036 8.09718 16.3327 6.61133 17.3876"
        stroke="#585858"
        strokeWidth="1.02862"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.91797V20.082M20.082 12H3.91797"
        stroke="#585858"
        strokeWidth="1.02862"
        strokeMiterlimit="10"
      />
    </g>
  </SvgIcon>
);

export const FacebookSecondIcon = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.2C15.9765 19.2 19.2 15.9765 19.2 12C19.2 8.02355 15.9765 4.8 12 4.8C8.02355 4.8 4.8 8.02355 4.8 12C4.8 15.9765 8.02355 19.2 12 19.2ZM12 21C16.9705 21 21 16.9705 21 12C21 7.02943 16.9705 3 12 3C7.02943 3 3 7.02943 3 12C3 16.9705 7.02943 21 12 21Z"
        fill="#585858"
      />
      <path
        d="M11.9984 10.2C11.9984 9.70295 12.4014 9.3 12.8984 9.3H13.7984C14.2955 9.3 14.6984 8.89706 14.6984 8.4C14.6984 7.90295 14.2955 7.5 13.7984 7.5H12.8984C11.4072 7.5 10.1984 8.70884 10.1984 10.2V12H9.29844C8.80139 12 8.39844 12.4029 8.39844 12.9C8.39844 13.3971 8.80138 13.8 9.29844 13.8H10.1984V19.2C10.1984 19.6971 10.6014 20.1 11.0984 20.1C11.5955 20.1 11.9984 19.6971 11.9984 19.2V13.8H13.7984C14.2955 13.8 14.6984 13.3971 14.6984 12.9C14.6984 12.4029 14.2955 12 13.7984 12H11.9984V10.2Z"
        fill="#585858"
      />
    </g>
  </SvgIcon>
);

export const InstagramSecondIcon = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6021 4.80001H8.40199C6.41375 4.80001 4.80197 6.4118 4.80197 8.40004V15.6001C4.80197 17.5883 6.41375 19.2001 8.40199 19.2001H15.6021C17.5903 19.2001 19.2021 17.5883 19.2021 15.6001V8.40004C19.2021 6.4118 17.5903 4.80001 15.6021 4.80001ZM8.40199 3C5.41963 3 3.00195 5.41768 3.00195 8.40004V15.6001C3.00195 18.5824 5.41963 21.0001 8.40199 21.0001H15.6021C18.5844 21.0001 21.0021 18.5824 21.0021 15.6001V8.40004C21.0021 5.41768 18.5844 3 15.6021 3H8.40199Z"
        fill="#585858"
      />
      <path
        d="M16.5 8.25001C16.9142 8.25001 17.25 7.91422 17.25 7.50001C17.25 7.08579 16.9142 6.75 16.5 6.75C16.0858 6.75 15.75 7.08579 15.75 7.50001C15.75 7.91422 16.0858 8.25001 16.5 8.25001Z"
        fill="#585858"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5001 12C16.5001 14.4853 14.4853 16.5001 12 16.5001C9.51474 16.5001 7.5 14.4853 7.5 12C7.5 9.51474 9.51474 7.5 12 7.5C14.4853 7.5 16.5001 9.51474 16.5001 12ZM14.7001 12C14.7001 13.4913 13.4913 14.7001 12 14.7001C10.5088 14.7001 9.30001 13.4913 9.30001 12C9.30001 10.5088 10.5088 9.30001 12 9.30001C13.4913 9.30001 14.7001 10.5088 14.7001 12Z"
        fill="#585858"
      />
    </g>
  </SvgIcon>
);

export const BurgerIcon = (props) => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.25 0H0.75C0.335786 0 0 0.335786 0 0.75C0 1.16421 0.335786 1.5 0.75 1.5H14.25C14.6642 1.5 15 1.16421 15 0.75C15 0.335786 14.6642 0 14.25 0Z"
      fill="white"
    />
    <path
      d="M14.25 4.5H0.75C0.335786 4.5 0 4.83579 0 5.25C0 5.66421 0.335786 6 0.75 6H14.25C14.6642 6 15 5.66421 15 5.25C15 4.83579 14.6642 4.5 14.25 4.5Z"
      fill="white"
    />
    <path
      d="M14.25 9H0.75C0.335786 9 0 9.33579 0 9.75C0 10.1642 0.335786 10.5 0.75 10.5H14.25C14.6642 10.5 15 10.1642 15 9.75C15 9.33579 14.6642 9 14.25 9Z"
      fill="white"
    />
  </svg>
);

export const CrossIcon = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3028 10.2425L1.75689 0.696605C1.464 0.403712 0.989126 0.403712 0.696232 0.696605C0.403339 0.989498 0.403339 1.46437 0.696232 1.75727L10.2422 11.3032C10.5351 11.5961 11.0099 11.5961 11.3028 11.3032C11.5957 11.0103 11.5957 10.5354 11.3028 10.2425Z"
      fill="white"
    />
    <path
      d="M10.2423 0.696449L0.696346 10.2424C0.403452 10.5353 0.403452 11.0102 0.696346 11.3031C0.989239 11.5959 1.46411 11.5959 1.75701 11.3031L11.3029 1.75711C11.5958 1.46422 11.5958 0.989342 11.3029 0.696449C11.0101 0.403556 10.5352 0.403556 10.2423 0.696449Z"
      fill="white"
    />
  </svg>
);

export const VectorIcon = (props) => (
  <svg
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.561668 1.94125L4.92979 7.03875C5.0002 7.12087 5.08754 7.18679 5.18582 7.23198C5.2841 7.27718 5.39099 7.30058 5.49917 7.30058C5.60734 7.30058 5.71423 7.27718 5.81251 7.23198C5.91079 7.18679 5.99813 7.12087 6.06854 7.03875L10.4367 1.94125C10.8535 1.45469 10.5079 0.703125 9.86729 0.703125H1.12979C0.489168 0.703125 0.143543 1.45469 0.561668 1.94125Z"
      fill="white"
    />
  </svg>
);

export const ChevronRightIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 17L14.5 12L9.5 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ExternalLinkIcon = (props) => (
  <svg
    className="absolute right-6 md:relative md:right-0"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <path
      d="M12.4434 9.09827V11.0818C12.4434 11.2605 12.4082 11.4374 12.3398 11.6025C12.2714 11.7675 12.1712 11.9175 12.0448 12.0439C11.9185 12.1702 11.7685 12.2704 11.6034 12.3388C11.4384 12.4072 11.2614 12.4424 11.0828 12.4424H2.91919C2.55834 12.4424 2.21226 12.299 1.9571 12.0439C1.70194 11.7887 1.55859 11.4426 1.55859 11.0818V2.91821C1.55859 2.55736 1.70194 2.21129 1.9571 1.95613C2.21226 1.70097 2.55834 1.55762 2.91919 1.55762H5.06643M8.63369 1.55762H12.4434V5.36729M7.00098 7L12.1712 1.82974"
      stroke="#153568"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MobileDropdown = (props) => (
  <svg
    className="hidden h-2 w-2 lg:inline-block"
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.561668 1.94125L4.92979 7.03875C5.0002 7.12087 5.08754 7.18679 5.18582 7.23198C5.2841 7.27718 5.39099 7.30058 5.49917 7.30058C5.60734 7.30058 5.71423 7.27718 5.81251 7.23198C5.91079 7.18679 5.99813 7.12087 6.06854 7.03875L10.4367 1.94125C10.8535 1.45469 10.5079 0.703125 9.86729 0.703125H1.12979C0.489168 0.703125 0.143543 1.45469 0.561668 1.94125Z"
      fill="white"
    ></path>
  </svg>
);

export const DesktopDropdown = (props) => (
  <svg
    className="lg:hidden"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    {...props}
  >
    <path
      d="M1 1.5L6 6.5L11 1.5"
      stroke="#232429"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3364 17.7792C11.3182 17.7792 12.1141 16.9833 12.1141 16.0014C12.1141 15.0196 11.3182 14.2236 10.3364 14.2236C9.35453 14.2236 8.55859 15.0196 8.55859 16.0014C8.55859 16.9833 9.35453 17.7792 10.3364 17.7792Z"
        stroke="#232429"
        strokeWidth="1.5"
      />
      <path
        d="M20.1098 11.5553C21.0916 11.5553 21.8876 10.7594 21.8876 9.77753C21.8876 8.79569 21.0916 7.99976 20.1098 7.99976C19.128 7.99976 18.332 8.79569 18.332 9.77753C18.332 10.7594 19.128 11.5553 20.1098 11.5553Z"
        stroke="#232429"
        strokeWidth="1.5"
      />
      <path d="M11.7773 14.75L18.3093 10.9788" stroke="#232429" strokeWidth="1.5" />
      <path
        d="M20.1098 20.4442C21.0916 20.4442 21.8876 21.2401 21.8876 22.222C21.8876 23.2038 21.0916 23.9998 20.1098 23.9998C19.128 23.9998 18.332 23.2038 18.332 22.222C18.332 21.2401 19.128 20.4442 20.1098 20.4442Z"
        stroke="#232429"
        strokeWidth="1.5"
      />
      <path d="M11.7773 17.4685L18.3093 21.2397" stroke="#232429" strokeWidth="1.5" />
    </svg>
  );
};
