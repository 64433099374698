import { useState, useLayoutEffect } from 'react';
export const useNode = (css) => {
  const currentNode = document.querySelector(css);
  const [node, setNode] = useState(currentNode);
  useLayoutEffect(() => {
    const node = document.querySelector(css);
    if (currentNode === node) return;
    setNode(node);
  }, [currentNode, css]);
  return [node];
};
