import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import Zendesk from 'react-zendesk';
import client from './apolloClient';
import Store from './store';
import { useConfigQuery, useMeAccount } from './hooks';
import loadGMaps from './utils/loadGoogleMapsAPI';
import {
  MainLayout,
  ScrollTop,
  ConfigProcessor,
  PrivateRoute,
  UpdateLocationModal,
} from './components';
import {
  BlankPage,
  CategoryPage,
  CartPage,
  Deals,
  Home,
  ProductDetailsPage,
  OrderSuccessfulPage,
  OrderCanceledPage,
  CheckoutPage,
  BrandsPage,
  BrandPage,
  SearchPage,
  NotFoundPage,
  SessionPage,
  AccountDetailsPage,
  OrderDetailsPage,
  OrderRequestReturnPage,
  OrderReturnSuccessfulPage,
  WholesaleLoginPage,
  WholesaleLoginSentPage,
  WholesaleSessionPage,
} from './pages';
import { ROUTING_CONFIG } from './constants';
import { getLocalStorageZipCode, updateManifestData, getStoreFrontSlug } from './utils';

import 'react-toastify/dist/ReactToastify.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Jessica from './components/jessica/jessica';

const generateTheme = ({ primary, secondary }) =>
  createTheme({
    spacing: 5,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1210,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeightMedium: 600,
    },
    palette: {
      primary: {
        main: primary || '#000',
      },
      secondary: {
        main: secondary || primary || '#000',
      },
      gray: {
        dark: '#232429',
        light: '#e8e8e8',
        hover: '#fbfbfb',
        athens: '#EBEFF2',
        main: '#939393',
      },
      white: {
        main: '#ffffff',
      },
      red: {
        main: '#D9000D',
        secondary: '#C33535',
        light: '#FBEBEB',
      },
      green: {
        main: '#2C802A',
        light: '#E2F5E2',
      },
      brown: {
        light: '#CC8A22',
      },
      yellow: {
        light: '#FFFFCB',
      },
      orange: {
        main: '#FF7A24',
        light: '#FFF2E5',
      },
      blue: {
        main: '#3580C3',
        light: '#E4F3FC',
      },
    },
  });

const RouteComponent =
  process.env.NODE_ENV === 'production' ? Sentry.withSentryReactRouterV6Routing(Route) : Route;

const InnerApp = () => {
  const { config, isLoading: isLoadingConfig } = useConfigQuery();
  const { account, isLoading: isLoadingMe } = useMeAccount();
  const [isGMapsAPILoaded, setGMapsAPIStatus] = useState(false);
  const chatbotId = config?.helpCenter?.chatbotId;
  const localZipCode = getLocalStorageZipCode();
  const slug = getStoreFrontSlug();
  const [isOpenLocationModal, setOpeningLocationModal] = React.useState(false);

  const handleShowingLocationModal = useCallback(() => {
    if (
      !window.location.href.includes('not-found') &&
      !window.location.href.includes('wholesale-login') &&
      !window.location.href.includes('wholesale-login-sent') &&
      !window.location.href.includes('wholesale-session') &&
      (!localZipCode || (account && !account?.zipCode))
    ) {
      setOpeningLocationModal(true);
    } else {
      setOpeningLocationModal(false);
    }
  }, [account, localZipCode]);

  useEffect(() => {
    if (config?.general?.name && config.general?.name !== document?.title) {
      document.title = config?.general?.name;
    }

    if (config?.checkout?.allowedLanguage) {
      loadGMaps(config?.checkout?.allowedLanguage, () => setGMapsAPIStatus(true));
    }
  }, [config]);

  useLayoutEffect(() => {
    const primaryColor = config?.general?.primaryColorHex || '#000';
    const secondaryColor = config?.general?.secondaryColorHex || '#000';

    const applyColors = () => {
      document.documentElement.style.setProperty('--color-tw-primary', primaryColor);
      document.documentElement.style.setProperty('--color-tw-secondary', secondaryColor);
    };

    // Apply colors initially
    applyColors();

    const handleOneTrustClose = () => {
      applyColors();
    };

    window.addEventListener('OneTrustGroupsUpdated', applyColors);

    // Target the OneTrust close button, if available
    const oneTrustCloseButton = document.querySelector('.ot-close-icon');
    oneTrustCloseButton?.addEventListener('click', handleOneTrustClose);

    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', applyColors);
      oneTrustCloseButton?.removeEventListener('click', handleOneTrustClose);
    };
  }, [config?.general?.primaryColorHex, config?.general?.secondaryColorHex]);

  useEffect(() => {
    handleShowingLocationModal();
  }, [account]);

  useEffect(() => {
    updateManifestData();
  }, [slug, account]);

  // if it's checkout page, which requires google maps API - block render until it loads; otherwise proceed as usual
  const blockRenderUntilGMapsAPILoaded =
    (window.location.href.includes('/cart/checkout') ||
      window.location.href.includes('/account-details')) &&
    !isGMapsAPILoaded;

  // TODO use spinner instead? problem is that createMuiTheme function crashes when there is no default color
  if (isLoadingConfig || isLoadingMe || blockRenderUntilGMapsAPILoaded) return null;

  return (
    <ThemeProvider
      theme={generateTheme({
        primary: config?.general?.primaryColorHex,
        secondary: config?.general?.secondaryColorHex,
      })}
    >
      <BrowserRouter>
        <ScrollTop />
        <Store config={config} account={account}>
          <ConfigProcessor config={config} />
          <UpdateLocationModal
            isOpen={isOpenLocationModal}
            handleCancel={() => setOpeningLocationModal(false)}
          />
          <MainLayout>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnHover
              limit={3}
            />
            {slug === 'salonhq-demo' && <Jessica />}
            <Routes>
              <RouteComponent path={ROUTING_CONFIG.home} element={<Home />} />
              <RouteComponent path={ROUTING_CONFIG.deals} element={<Deals />} />
              <RouteComponent
                path={ROUTING_CONFIG.aboutUs}
                element={<BlankPage title="About Us" />}
              />
              <RouteComponent path={ROUTING_CONFIG.blog} element={<BlankPage title="Blog" />} />
              <RouteComponent path={ROUTING_CONFIG.brands} element={<BrandsPage />} />
              <RouteComponent path={ROUTING_CONFIG.brand} element={<BrandPage />} />
              <RouteComponent path={ROUTING_CONFIG.category} element={<CategoryPage />} />
              <RouteComponent
                path={ROUTING_CONFIG.productDetails}
                element={<ProductDetailsPage />}
              />
              <RouteComponent path={ROUTING_CONFIG.cart} element={<CartPage />} />
              <RouteComponent path={ROUTING_CONFIG.checkout} element={<CheckoutPage />} />
              <RouteComponent
                path={ROUTING_CONFIG.orderSuccessful}
                element={<OrderSuccessfulPage />}
              />
              <RouteComponent
                path={ROUTING_CONFIG.orderReturnSuccessful}
                element={<OrderReturnSuccessfulPage />}
              />
              <RouteComponent path={ROUTING_CONFIG.orderCanceled} element={<OrderCanceledPage />} />
              <RouteComponent
                path={ROUTING_CONFIG.contactUs}
                element={<BlankPage title="Contact Us" />}
              />
              <RouteComponent
                path={ROUTING_CONFIG.siteMap}
                element={<BlankPage title="Site Map" />}
              />
              <RouteComponent
                path={ROUTING_CONFIG.privacyPolicy}
                element={<BlankPage title="Privacy Policy" />}
              />
              <RouteComponent
                path={ROUTING_CONFIG.termsAndConditions}
                element={<BlankPage title="Terms" />}
              />
              <RouteComponent path={ROUTING_CONFIG.search} element={<SearchPage />} />
              <RouteComponent path={ROUTING_CONFIG.notFound} element={<NotFoundPage />} />
              <RouteComponent path={ROUTING_CONFIG.session} element={<SessionPage />} />
              <RouteComponent
                path={ROUTING_CONFIG.wholesaleLogin}
                element={<WholesaleLoginPage />}
              />
              <RouteComponent
                path={ROUTING_CONFIG.wholesaleLoginSent}
                element={<WholesaleLoginSentPage />}
              />
              <RouteComponent
                path={ROUTING_CONFIG.wholesaleSession}
                element={<WholesaleSessionPage />}
              />
              <RouteComponent
                path={ROUTING_CONFIG.accountDetails}
                element={
                  <PrivateRoute>
                    <AccountDetailsPage />
                  </PrivateRoute>
                }
              />
              <RouteComponent
                path={ROUTING_CONFIG.orderHistoryDetails}
                element={
                  <PrivateRoute>
                    <OrderDetailsPage />
                  </PrivateRoute>
                }
              />
              <RouteComponent
                path={ROUTING_CONFIG.orderRequestReturn}
                element={
                  <PrivateRoute>
                    <OrderRequestReturnPage />
                  </PrivateRoute>
                }
              />
              <RouteComponent path="*" element={<Navigate to={ROUTING_CONFIG.notFound} />} />
            </Routes>
          </MainLayout>
          {chatbotId && <Zendesk defer zendeskKey={chatbotId} />}
        </Store>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const App = () => (
  <ApolloProvider client={client}>
    <InnerApp />
  </ApolloProvider>
);

export default App;
