import React, { useContext, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { MainModal } from '../modal';
import { UpdateLocationForm } from '../update-location-form';
import { zipCodeService } from '../../services';
import { ACTIONS_TYPES } from '../../constants';
import { Context } from '../../store';
import { useLocation } from 'react-router-dom';
import { useQueryString } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    zIndex: '10002 !important',
  },
  container: {
    padding: '15px 15px 20px',
  },
  button: {
    height: '28px',
    maxWidth: '88px',
    fontSize: '10px',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '130px',
      height: '30px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '170px',
      height: '35px',
      fontSize: '12px',
    },
  },
}));

export const UpdateLocationModal = ({ isOpen, handleCancel }) => {
  const classes = useStyles();
  const { dispatch } = useContext(Context);
  const { search } = useLocation();
  const params = useQueryString(search);

  useLayoutEffect(() => {
    if (params?.zipCode) {
      zipCodeService.zipCode = params?.zipCode;
      dispatch({ type: ACTIONS_TYPES.UPDATE_ZIP_CODE, payload: params?.zipCode });
      handleCancel();
    }
  }, []);

  const handleSubmit = ({ zipCode }) => {
    zipCodeService.zipCode = zipCode;
    dispatch({ type: ACTIONS_TYPES.UPDATE_ZIP_CODE, payload: zipCode });
    handleCancel();
    window.scrollTo(0, 0);
  };

  const handleCloseModal = () => {
    handleCancel?.();
    window.scrollTo(0, 0);
  };

  return (
    <MainModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      disablePortal={false}
      className={classes.wrapper}
    >
      <Grid container className={classes.container}>
        <Grid item align="center">
          <UpdateLocationForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </MainModal>
  );
};
