import React, { useLayoutEffect, useState } from 'react';
import { useNode } from '../../hooks';
const mountCss = '#jessica-mount';
const Jessica = () => {
  const [node] = useNode(mountCss);
  const nodeExists = !!node;
  const [inited, setInited] = useState(false);
  useLayoutEffect(() => {
    if (inited || !nodeExists) return;
    window.AiAgentsWebWidget.init({
      initialState: {
        mic: true,
        speaker: true,
        collapsed: true,
      },
      zIndex: 5000,
      openButtonContainerQuery: '#jessica-mount',
      widgetSize: 'medium',
      widgetTranscribeMode: 'none',
      widgetUserInputMode: '#jessica-mount',
      agentId: process.env.REACT_APP_AI_AGENT_ID,
      authUrl: process.env.REACT_APP_AI_AUTH_URL,
      authToken: process.env.REACT_APP_AI_AUTH_TOKEN,
    });
    setInited(true);
    return () => {};
  }, [inited, nodeExists]);
  return null;
};

export default React.memo(Jessica);
