import React, { useContext } from 'react';
import { ShareIcon } from '../icons/Icons';
import { Context } from '../../store';

export const ShareButton = () => {
  const { state } = useContext(Context);

  const openShareWindow = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: state.config.general.name,
          text: 'SalonHQ is the place for all of your hair care supplies from your favorite stylist!',
          url: window.location.href,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <button
      data-for={window.location.href}
      onClick={openShareWindow}
      id="home-share"
      className="p-1"
    >
      <ShareIcon className="h-8 w-8 text-black" />
    </button>
  );
};
